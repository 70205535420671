import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components'

import OccupancyItem from './Common/OccupancyItem'

import AddRoomButton from 'components/Common/AddRoomButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import config from 'constants/config'
import { maxFlightChildAge } from 'constants/flight'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import {
  isValidAccommodationSearchOccupancy,
  isValidFlightsOccupants,
} from 'lib/offer/occupancyUtils'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'

interface Props {
  tripId: string
  onSetOccupancies: (occupancies: Array<App.Occupants>) => void
  initOccupants: Array<App.Occupants>
  isFlights?: boolean
}

const MAX_CHILD_AGE_ACCOMM_SEARCH = 17

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

function SearchOccupancyPicker(props: Props) {
  const { initOccupants, onSetOccupancies, isFlights = false } = props

  const maxChildAge = isFlights ?
    maxFlightChildAge :
    MAX_CHILD_AGE_ACCOMM_SEARCH

  const { closeModal, goBack } = useModalCallbacks()

  const { disableMultiRoom } = useContext(GlobalSearchStateContext)

  const [occupancies, setOccupancies] = useState<Array<App.Occupants>>(initOccupants)

  const isValidOccupany = useMemo(() => {
    if (isFlights) {
      return isValidFlightsOccupants(occupancies[0])
    }
    return isValidAccommodationSearchOccupancy(
      occupancies,
      MAX_CHILD_AGE_ACCOMM_SEARCH,
    )
  }, [occupancies, isFlights])

  const addRoom = useCallback((occupancies: Array<App.Occupants>) => {
    const newOccupancies = [
      ...occupancies,
      { ...config.search.defaultOccupants },
    ]
    setOccupancies(newOccupancies)
  }, [])

  const removeItem = useCallback(
    (occupancies: Array<App.Occupants>, index: number) => {
      const newOccupancies = [...occupancies]
      newOccupancies.splice(index, 1)
      setOccupancies(newOccupancies)
    },
    [],
  )

  const resetItem = useCallback((occupancies, index: number) => {
    const newOccupancies = [...occupancies]
    newOccupancies[index] = { ...config.search.defaultOccupants }
    setOccupancies(newOccupancies)
  }, [])

  const changeItem = useCallback(
    (
      occupancies: Array<App.Occupants>,
      newOccupancy: App.Occupants,
      index: number,
    ) => {
      const newOccupancies = [...occupancies]
      newOccupancies[index] = newOccupancy
      setOccupancies(newOccupancies)
    },
    [],
  )

  const onApply = useCallback(
    (occupancies: Array<App.Occupants>) => {
      onSetOccupancies(occupancies)

      if (goBack) {
        goBack()
      }
    },
    [goBack, onSetOccupancies],
  )

  const modalTitle = isFlights ?
    'Select your travellers' :
    'Select your rooms & guests'

  const onClickApply = useCallback(
    () => onApply(occupancies),
    [occupancies, onApply],
  )

  return (
    <>
      <ModalHeader
        title={modalTitle}
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <ModalBody>
        <StyledModalContent>
          <VerticalSpacer gap={8}>
            {occupancies.map((o, index) => (
              <OccupancyItem
                occupancy={o}
                itemId={index}
                key={index}
                title={isFlights ? undefined : `Room ${index + 1}`}
                showRemove={index > 0}
                onReset={() => resetItem(occupancies, index)}
                onRemove={() => removeItem(occupancies, index)}
                onChange={(_id, occ) => {
                  changeItem(occupancies, occ, index)
                }}
                maxChildAge={maxChildAge}
              />
            ))}
            {!disableMultiRoom &&
              !isFlights &&
              config.accommodation.allowsMultiRoom && (
                <AddRoomButton
                  roomsCount={occupancies.length}
                  onClick={() => addRoom(occupancies)}
                />
            )}
          </VerticalSpacer>
        </StyledModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Apply',
          onClick: onClickApply,
          disabled: !isValidOccupany,
        }}
      />
    </>
  )
}

export default SearchOccupancyPicker
