import { rem } from 'polished'
import styled from 'styled-components'

export const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  grid-column-gap: ${rem(12)};
`

export const AddDetailsCopy = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  padding: ${rem(20)};
  margin-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
  border-radius: ${(props) => props.theme.borderRadius.M};
  width: 100%;
`
