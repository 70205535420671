import { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'

import CalendarDateRangePicker from 'components/Common/Calendar/DateRangePicker'
import { mediaQueryUp } from 'components/utils/breakpoint'

interface Props {
  date?: Moment
  minDate?: Moment
  maxDate?: Moment
  onDateChange: (date?: Moment) => void
}

// Force the DatePicker to have a min-height so it doesn't jump up and down
// Pending fixing this in the design library
const DatePickerMinHeightWrapper = styled.div`
  min-height: 271px;

  ${mediaQueryUp.tablet} {
    min-height: 340px;
  }
`

function DatePicker({ date, onDateChange, minDate, maxDate }: Props) {
  return (
    <DatePickerMinHeightWrapper>
      <CalendarDateRangePicker
        onDatesChange={(dates) => {
          onDateChange(dates.startDate)
        }}
        startLabel="Start date"
        startDate={date}
        endDate={date}
        minDate={minDate?.toDate()}
        maxDate={maxDate?.toDate()}
      />
    </DatePickerMinHeightWrapper>
  )
}

export default DatePicker
