import {
  CreateThingToDoItemViewModel,
  CreateThingToDoItemRequest,
  CreateRestaurantBarItemViewModel,
  CreateRestaurantBarItemRequest,
  CreateCarRentalItemRequest,
  CreateCarRentalItemViewModel,
  CreateOtherTransportItemViewModel,
  CreateOtherTransportItemRequest,
  CreateFerryItemViewModel,
  CreateFerryItemRequest,
  CreateTrainItemViewModel,
  CreateTrainItemRequest,
  CreateBusItemRequest,
  CreateBusItemViewModel,
  CreateNoteItemRequest,
  CreateNoteItemViewModel,
  CreateEventItemViewModel,
  CreateEventItemRequest,
  CreateAttractionItemViewModel,
  CreateAttractionItemRequest,
  CreateCruiseItemViewModel,
  CreateCruiseItemRequest,
  CreateTourItemViewModel,
  CreateTourItemRequest,
  CreateFlightItemViewModel,
  CreateFlightItemRequest,
  CreateAccommodationItemRequest,
  CreateAccommodationItemViewModel,
} from './models'

export interface CreateItemProps {
  tripId: string
  defaultDate?: string
}

export const createFlightPayloadFromViewModel = (
  viewModel: CreateFlightItemViewModel,
): CreateFlightItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createAccommodationPayloadFromViewModel = (
  viewModel: CreateAccommodationItemViewModel,
): CreateAccommodationItemRequest => {
  const {
    place,
    startDate,
    startTime,
    endDate,
    endTime,
    type,
    code,
    confirmationCode,
    confirmationItemCodes,
    confirmationUrl,
    notes,
    phone,
    price,
    sourceType,
    isBooked,
    url,
  } = viewModel

  return {
    isBooked,
    place,
    startDate,
    startTime,
    endDate,
    endTime,
    type,
    code,
    confirmationCode,
    confirmationItemCodes,
    confirmationUrl,
    notes,
    phone,
    price,
    sourceType,
    url,
  }
}

export const createTourPayloadFromViewModel = (
  viewModel: CreateTourItemViewModel,
): CreateTourItemRequest => {
  const { isEndDateSame, isEndPlaceSame, ...rest } = viewModel

  return rest
}

export const createCruisePayloadFromViewModel = (
  viewModel: CreateCruiseItemViewModel,
): CreateCruiseItemRequest => {
  const { isEndDateSame, isEndPlaceSame, ...rest } = viewModel

  return rest
}

export const createAttractionPayloadFromViewModel = (
  viewModel: CreateAttractionItemViewModel,
): CreateAttractionItemRequest => {
  const { ...rest } = viewModel

  return rest
}

export const createEventPayloadFromViewModel = (
  viewModel: CreateEventItemViewModel,
): CreateEventItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createThingToDoPayloadFromViewModel = (
  viewModel: CreateThingToDoItemViewModel,
): CreateThingToDoItemRequest => {
  const { isEndDateSame, isEndPlaceSame, ...rest } = viewModel

  return rest
}

export const createNotePayloadFromViewModel = (
  viewModel: CreateNoteItemViewModel,
): CreateNoteItemRequest => {
  const { ...rest } = viewModel

  return rest
}

export const createBusPayloadFromViewModel = (
  viewModel: CreateBusItemViewModel,
): CreateBusItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createTrainPayloadFromViewModel = (
  viewModel: CreateTrainItemViewModel,
): CreateTrainItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createFerryPayloadFromViewModel = (
  viewModel: CreateFerryItemViewModel,
): CreateFerryItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createOtherTransportPayloadFromViewModel = (
  viewModel: CreateOtherTransportItemViewModel,
): CreateOtherTransportItemRequest => {
  const { isEndDateSame, ...rest } = viewModel

  return rest
}

export const createCarRentalPayloadFromViewModel = (
  viewModel: CreateCarRentalItemViewModel,
): CreateCarRentalItemRequest => {
  const { isEndDateSame, isEndPlaceSame, ...rest } = viewModel

  return rest
}

export const createRestaurantBarPayloadFromViewModel = (
  viewModel: CreateRestaurantBarItemViewModel,
): CreateRestaurantBarItemRequest => {
  const { ...rest } = viewModel

  return rest
}
