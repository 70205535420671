import moment from 'moment'
import { rem } from 'polished'
import React, { PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'

import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'

const Container = styled(Clickable)`
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.palette.neutral.default.eight};
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  border-radius: ${(props) => props.theme.borderRadius.S};
`

export const Item = styled.div`
  padding: ${rem(8)} ${rem(16)};
  width: 100%;
  justify-self: stretch;

  &:not(:first-child) {
    border-left: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  }
`

interface BaseProps extends PropsWithChildren<{}> {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

interface PlaceProps extends BaseProps {
  title?: string
  subTitle?: string
}

const PLACE_DEFAULT_TITLE = 'Where'
const PLACE_DEFAULT_SUB_TITLE = 'Search for a destination or hotel'

export function PlaceInputTrigger({
  onClick,
  title = PLACE_DEFAULT_TITLE,
  subTitle = PLACE_DEFAULT_SUB_TITLE,
}: PlaceProps) {
  return (
    <Container onClick={onClick}>
      <Item>
        <Caption variant="large" weight="bold">
          {title}
        </Caption>
        <BodyText colour="neutral-three" variant="medium">
          {subTitle}
        </BodyText>
      </Item>
    </Container>
  )
}

interface DatesProps extends BaseProps {
  checkinDate?: moment.Moment
  checkoutDate?: moment.Moment
  title1?: string
  subTitle1?: string
  title2?: string
  subTitle2?: string
  useSingleDate?: boolean
}

const DEFAULT_DATES_SUB_TITLE = 'Anytime'

export function DatesInputTrigger({
  onClick,
  checkinDate,
  checkoutDate,
  title1 = 'Check in',
  subTitle1 = DEFAULT_DATES_SUB_TITLE,
  title2 = 'Check out',
  subTitle2 = DEFAULT_DATES_SUB_TITLE,
  useSingleDate,
}: DatesProps) {
  return (
    <Container onClick={onClick}>
      <Item>
        <Caption variant="large" weight="bold">
          {title1}
        </Caption>
        <BodyText
          variant="medium"
          colour={checkinDate ? 'neutral-one' : 'neutral-three'}
        >
          {checkinDate ? checkinDate.format(DMY_CASUAL_FORMAT) : subTitle1}
        </BodyText>
      </Item>
      {!useSingleDate && (
        <Item>
          <Caption variant="large" weight="bold">
            {title2}
          </Caption>
          <BodyText
            variant="medium"
            colour={checkoutDate ? 'neutral-one' : 'neutral-three'}
          >
            {checkoutDate ? checkoutDate.format(DMY_CASUAL_FORMAT) : subTitle2}
          </BodyText>
        </Item>
      )}
    </Container>
  )
}

interface GuestsProps extends BaseProps {
  title?: string
  occupancies: Array<App.Occupants>
  isFlights?: boolean
}

const DEFAULT_GUESTS_TITLE = 'Guests'

export function GuestsInputTrigger({
  onClick,
  title = DEFAULT_GUESTS_TITLE,
  occupancies,
  isFlights = false,
}: GuestsProps) {
  const guestsInsteadOfAdults = occupancies.some(
    (room) => room.childrenAge?.length,
  )
  const guestCount = useMemo(
    () =>
      sum(
        occupancies,
        (room) =>
          room.adults + (room.childrenAge ? room.childrenAge.length : 0),
      ),
    [occupancies],
  )

  return (
    <Container onClick={onClick}>
      <Item>
        <Caption variant="large" weight="bold">
          {title}
        </Caption>
        <BodyText variant="medium">
          {pluralizeToString(
            guestsInsteadOfAdults ? 'guest' : 'adult',
            guestCount,
          )}{' '}
          {!isFlights && `• ${pluralizeToString('room', occupancies.length)}`}
        </BodyText>
      </Item>
    </Container>
  )
}
