import { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'

import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import {
  ISO_DATE_FORMAT,
  SHORT_DAY_NAME_DAY_MONTH,
  SHORT_DAY_NAME_DAY_MONTH_YEAR,
} from 'constants/dateFormats'
import { pluralizeToString } from 'lib/string/pluralize'

const TimeText = styled(BodyText)``

const Dates = styled.div`
  width: 100%;
`

interface Props {
  start?: Moment
  end?: Moment
}

function DatesPickedText({ start, end }: Props) {
  if (!start && !end) {
    return null
  }

  if (!end && start) {
    return (
      <Dates>
        <TimeText
          variant="medium"
          weight="semi-bold"
          forwardedAs="time"
          dateTime={start.format(ISO_DATE_FORMAT)}
        >
          {start.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
        </TimeText>
      </Dates>
    )
  }

  return (
    <Group
      direction="horizontal"
      verticalAlign="center"
      gap={8}
      wrap="wrap"
      fullWidth
    >
      <Group direction="horizontal" verticalAlign="center" gap={8}>
        <TimeText
          variant="medium"
          weight="semi-bold"
          forwardedAs="time"
          dateTime={start!.format(ISO_DATE_FORMAT)}
        >
          {start!.format(
            start!.isSame(end, 'year') ?
              SHORT_DAY_NAME_DAY_MONTH :
              SHORT_DAY_NAME_DAY_MONTH_YEAR,
          )}
        </TimeText>
        <LineArrowRightIcon size="XS" />
        <TimeText
          variant="medium"
          weight="semi-bold"
          forwardedAs="time"
          dateTime={end!.format(ISO_DATE_FORMAT)}
        >
          {end!.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
        </TimeText>{' '}
      </Group>
      <BodyText as="span" variant="medium">
        ({pluralizeToString('day', end!.diff(start, 'days') + 1)})
      </BodyText>
    </Group>
  )
}

export default DatesPickedText
